<template>
  <Item
    :item="item"
    :parent="parent"
    :style="{
      fontSize:
        increaseRelativeSize && parent.props.fontSize !== 'xl'
          ? '110%'
          : undefined,
    }"
  >
    <component
      :is="isAdminMode ? 'div' : hrefType === 'internal' ? 'InternalLink' : 'a'"
      :target-id="hrefType === 'internal' ? url : undefined"
      :href="hrefType === 'external' ? url : undefined"
      :class="btnClasses"
      rel="noopener"
      class="inline-flex font-normal"
      :style="{ paddingBlock: isNarrow ? '0.5em' : undefined }"
    >
      <component :is="icon" v-if="iconName" />

      <Editor
        v-if="isAdminMode"
        :item-name="item.name"
        :content="content"
        @update="onEditorUpdate"
      />

      <div v-else v-html="content" />
    </component>
  </Item>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'Btn',

  components: {
    Item: () => import('@/components/Item'),
    Editor: () => import('@/components/Editor'),
    InternalLink: () => import('@/components/InternalLink'),
  },

  mixins: [itemMixin],

  props: {
    increaseRelativeSize: {
      type: Boolean,
      default: true,
    },

    isNarrow: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: null,
    },
  },

  computed: {
    pageItems: get('page/items'),
    btnShape: get('page/btnShape'),
    btnStyle: get('page/btnStyle'),

    hrefType() {
      const { url } = this.item.settings

      return url ? 'external' : 'internal'
    },

    style() {
      return this.item.settings.style
    },

    url() {
      const { url, sectionUid } = this.item.settings

      if (sectionUid) {
        const section = this.pageItems.find(item => item.uid === sectionUid)
        if (!section) {
          return
        }

        if (section.slug) {
          return section.slug
        }
        return section.uid
      }

      return url
    },

    content() {
      return this.item.content.html
    },

    iconName() {
      return this.item.settings.iconName
    },

    icon() {
      if (!this.iconName) {
        return null
      }

      return () => import(`~/assets/icons-public/${this.iconName}.svg?inline`)
    },

    parentFontSize() {
      return this.parent.props.fontSize ?? 'md'
    },

    btnClasses() {
      let classes = {}

      if (this.iconName) {
        classes['public-btn--icon'] = true
      }

      classes = {
        ...classes,
        'public-btn--sm': this.parentFontSize === 'sm',
        'public-btn--lg': ['lg', 'xl'].includes(this.parentFontSize),
      }

      if (this.style === 'text') {
        classes['public-btn public-btn--link font-bold'] = true
        return classes
      }

      classes['public-btn'] = true

      classes = {
        ...classes,
        'public-btn--rounded': this.btnShape === 'rounded',
        'public-btn--rounded-full': this.btnShape === 'roundedFull',
        'public-btn--gradient': this.btnStyle === 'gradient',
      }

      if (this.style === 'outline') {
        classes['public-btn--outline'] = true
      }

      return classes
    },
  },

  methods: {
    onEditorUpdate(value) {
      dispatch('page/update', {
        mutation: 'UPDATE_ITEM_CONTENT',
        item: this.item,
        value,
      })
    },
  },
}
</script>

<style lang="postcss">
[data-item='Btn'] p {
  opacity: 1;
}
</style>
